
import { Component, Vue } from 'vue-property-decorator'
import { DetailInfo } from '@/types/guideComment'

@Component({
  name: 'GuideCommentDetail'
})
export default class extends Vue {
  private detailInfo: DetailInfo | null = null

  created () {
    const id = this.$route.params.id as string
    this.getDetail(id)
  }

  getDetail (id: string) {
    this.$axios
      .get(this.$apis.guideComment.selectEvaluateById, { id })
      .then((res) => {
        this.detailInfo = res
      })
  }
}
